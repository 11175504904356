export const all = (state) => {
  return state.resources.map((resource) => {
    return new state.modelClass(resource)
  })
}

export const find = (state) => (id) => {
  const resource = state.resources.find((resource) => {
    return String(resource.id) === String(id)
  })

  return new state.modelClass(resource)
}

export const findBy = (state) => (attributes) => {
  return new state.modelClass(
    state.resources.find((record) => {
      return Object.keys(attributes).every((key) => {
        return record[key] === attributes[key]
      })
    })
  )
}

export const filterBy = (state) => (attributes) => {
  return state.resources
    .filter((record) => {
      return Object.keys(attributes).every((key) => {
        return record[key] === attributes[key]
      })
    })
    .map((resource) => new state.modelClass(resource))
}
